import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import BlogBlock from "../blogs/BlogBlock"
import SectionHeader from "../common/SectionHeader"

const RecentPosts = ({ title, subtitle, recentPosts }) => (
  <section id="recentPosts" className="p-10 bg-sym-gray">
    <SectionHeader
      title={title}
      subtitle={subtitle}
      titleColor="white"
      subtitleColor="white"
    />
    <div className="container mx-auto flex flex-wrap mt-10 justify-center">
      {recentPosts.length > 0 &&
        recentPosts.map(b => <BlogBlock key={b.slug} blog={b} />)}
    </div>
    <p className="text-center text-white mt-10 text-xl">
      <Link to="/blog/" className="underline">
        Ver más publicaciones
      </Link>
    </p>
  </section>
)

RecentPosts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  recentPosts: PropTypes.array,
}

RecentPosts.defaultProps = {
  title: `Últimas Entradas`,
  subtitle: ``,
  recentPosts: [],
}

export default RecentPosts
