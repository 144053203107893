import React from "react"
import PropTypes from "prop-types"

import { goToID } from "../../utils/helpers"

const Hero = ({ image, title, subtitle, action }) => (
  <section className="wrapper bg-sym-gray h-80 pt-24 md:h-auto md:pt-0">
    <div className="relative">
      <div className="relative hidden md:block w-full ">
        <img src={image} width="100%" alt="Hero" />
      </div>
      <div className="absolute w-full top-1/4 text-center">
        <h1 className="text-sym-blue text-4xl md:text-6xl font-black">
          {title}
        </h1>
        {subtitle !== "" && (
          <h2 className="font-medium text-white block mx-auto mt-6 w-2/3 text-3xl md:text-4xl">
            {subtitle}
          </h2>
        )}
        <button
          className="hidden md:block bg-gradient-to-b from-sym-purple to-sym-light-blue py-6 px-16 mt-10 rounded-full text-2xl font-bold mx-auto text-white hover:from-sym-sym-purple hover:to-sym-purple"
          type="button"
          onClick={e => goToID(e, "#contact")}
        >
          {action}
        </button>
      </div>
    </div>
  </section>
)

Hero.propTypes = {
  action: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
}

Hero.defaultProps = {
  action: ``,
  title: ``,
  subtitle: ``,
  image: ``,
}

export default Hero
