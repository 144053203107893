import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/common/Hero"
import Services from "../components/home/Services"
import Projects from "../components/home/Projects"
import ClientStrip from "../components/projects/ClientStrip"
import ToTop from "../components/common/ToTop"
import Contact from "../components/home/Contact"
import RecentPosts from "../components/home/RecentPosts"

const IndexPage = ({ location }) => {
  const { strapiHomepage, allStrapiBlog } = useStaticQuery(query)
  const {
    headerImage,
    services,
    HeroHeader,
    CallToActionText,
    clients,
    projects,
  } = strapiHomepage

  const { nodes: posts } = allStrapiBlog

  return (
    <Layout pathname={location.pathname || "/"}>
      <Seo />
      <Hero
        image={headerImage.url}
        title={HeroHeader[0].Header}
        subtitle={HeroHeader[0].SubHeader}
        action={CallToActionText}
      />
      <Services
        pretitle={services.Header.pretitle}
        title={services.Header.title || ""}
        subtitle={services.Header.subtitle || ""}
        services={services.ServicesBlock}
      />
      <Projects
        projects={projects.projects}
        title={projects.Header.title}
        subtitle={projects.Header.subtitle}
      />
      <ClientStrip title={clients.Header.title} clients={clients.clients} />
      <Contact />
      <RecentPosts recentPosts={posts} />
      <ToTop />
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      locale
      headerImage {
        alternativeText
        caption
        url
        formats {
          medium {
            url
            width
          }
        }
      }
      CallToActionText
      HeroHeader {
        Header
        SubHeader
        id
      }
      services {
        Header {
          id
          pretitle
        }
        ServicesBlock {
          text
          title
          icon {
            url
          }
        }
      }
      clients {
        Header {
          title
        }
        clients {
          alt
          id
          logo {
            url
          }
          url
        }
      }
      projects {
        Header {
          subtitle
          title
        }
        projects {
          id
          url
          title
          image {
            url
            size
            alternativeText
          }
          description
        }
      }
    }
    allStrapiBlog(
      filter: { status: { eq: "published" } }
      sort: { fields: PublishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        PublishDate
        title
        slug
        status
        excerpt
        image: cover_image {
          caption
          alternativeText
          formats {
            posts {
              width
              height
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
