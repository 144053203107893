import React from "react"
import PropTypes from "prop-types"

const ServiceBlock = ({ service }) => (
  <div className="w-full md:w-1/3 p-4">
    <a href="/">
      <div className="bg-gray-100 shadow-md p-6 rounded-md h-full transition-transform duration-500 ease-in-out transform hover:scale-105">
        <div className="py-4">
          <img src={service.icon?.url} alt={service.title} />
        </div>
        <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
        <p className="font-light text-xl leading-8">{service.text}</p>
      </div>
    </a>
  </div>
)

ServiceBlock.propTypes = {
  service: PropTypes.object,
}

export default ServiceBlock
