import React from "react"
import PropTypes from "prop-types"

import ServiceBlock from "../services/ServiceBlock"
import SectionHeader from "../common/SectionHeader"

const Services = ({ pretitle, title, subtitle, services }) => (
  <section id="services" className="py-20">
    <SectionHeader pretitle={pretitle} subtitle={subtitle} />
    <div className="container mx-auto flex flex-wrap mt-10">
      {services.length > 0 &&
        services.map(s => <ServiceBlock key={s.title} service={s} />)}
    </div>
  </section>
)

Services.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  services: PropTypes.array,
}

Services.defaultProps = {
  pretitle: ``,
  title: ``,
  subtitle: ``,
  services: [],
}

export default Services
