import React from "react"
import PropTypes from "prop-types"

import "./project-block.css"

const ProjectBlock = ({ project }) => (
  <div className="project-block w-full md:w-1/4 p-2 relative bg-sym-gray mx-auto">
    <a href={project.url} target="_blank" rel="noreferrer">
      <div className="project-content shadow-md rounded-md h-full transition-transform duration-500 ease-in-out transform hover:scale-105">
        <div className="project-overlay"></div>
        <div className="project-image">
          <img src={project.image.url} alt={project.title} />
        </div>
        <div className="absolute project-title">
          <h3 className="text-xl text-white font-semibold mb-4">
            {project.title}
          </h3>
        </div>
        <div className="absolute project-description">
          <p className="text-white text-xl leading-8">{project.description}</p>
        </div>
      </div>
    </a>
  </div>
)

ProjectBlock.propTypes = {
  project: PropTypes.object,
}

export default ProjectBlock
