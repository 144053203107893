import React from "react"
import PropTypes from "prop-types"

import ProjectBlock from "../projects/ProjectBlock"
import SectionHeader from "../common/SectionHeader"

const Projects = ({ title, subtitle, projects }) => (
  <section id="projects" className="p-10 bg-sym-gray">
    <SectionHeader
      title={title}
      subtitle={subtitle}
      titleColor="white"
      subtitleColor="white"
    />
    <div className="container mx-auto flex flex-wrap">
      {projects.length > 0 &&
        projects.map(p => <ProjectBlock key={p.id} project={p} />)}
    </div>
  </section>
)

Projects.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  projects: PropTypes.array,
}

Projects.defaultProps = {
  title: `Proyectos`,
  subtitle: `Nuestros últimos proyectos`,
  projects: [],
}

export default Projects
