import React from "react"
import PropTypes from "prop-types"

import SectionHeader from "../common/SectionHeader"

const ClientStrip = ({ pretitle, title, subtitle, clients }) => {
  return (
    <section id="clients" className="container mx-auto mt-10">
      <SectionHeader title={title} pretitle={pretitle} subtitle={subtitle} />
      <div className="mt-6 flex flex-wrap mx-auto" style={{ maxWidth: 800 }}>
        {clients.length > 0 &&
          clients.map(c => (
            <div
              className="w-full md:w-1/4 text-center p-4 mx-auto my-auto"
              key={c.id}
            >
              <img
                className="mx-auto"
                style={{ maxHeight: 100, maxWidth: 150 }}
                src={c.logo.url}
                alt={c.alt}
                title={c.alt}
              />
            </div>
          ))}
      </div>
    </section>
  )
}

ClientStrip.propTypes = {
  pretitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  clients: PropTypes.array,
}

ClientStrip.defaultProps = {
  pretitle: ``,
  title: `Clientes`,
  subtitle: ``,
  clients: [],
}

export default ClientStrip
